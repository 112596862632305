@import "../../../style/mixins";

.footer {
  width: 100%;
  padding: var(--uxu-space-large) 0;
  border-top: var(--uxu-border-default);
  background-color: var(--uxu-color-background-secound);
  
  a {
    color: var(--uxu-color-link-secound);
    
    &:hover {
      color: var(--uxu-color-link-hover);
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: 100%;

  @include media('m') {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media('xl') {
    grid-template-columns: repeat(5, 1fr);
  }
}

.column, .wrapperBrand {
  display: flex;
  flex-direction: column;
}

.column {
  margin: 0 auto;

  ul {
    min-width: 20rem;
    list-style: none;
    padding-bottom: var(--uxu-space-large);
    
    li {
      text-align: center;
      padding: var(--uxu-space-small);

      @include media('m') {
        text-align: left;
      }
    }
  }
}

.lastColumn {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @include media('m') {
    justify-content: flex-end;
  }
}

.wrapperBrand {
  a {
    text-align: center;
    margin: var(--uxu-space-default) auto;

    &.logo {
      margin-top: 0;
    }

    @include media('m') {
      margin-left: 0;
      text-align: left;
      &:nth-child(2) {
        margin-top: 100%;
      }
    }
  }

  ul {
    display: flex;
    margin: 0 auto;
    justify-content: center;

    @include media('m') {
      margin-left: -2rem;
      justify-content: flex-start;
    }

    li {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      @include dimensions(5rem, 3rem);
      padding-right: var(--uxu-space-small);

      a {
        margin: 0 auto;
      }

      &::after {
        content: "";
        right: 0;
        top: 0.1rem;
        position: absolute;
        @include dimensions(0.2rem, 2rem);
        background-color: var(--uxu-color-background-secound);
      }

      &:last-of-type::after {
        display: none;
      }
    }
  }
}

.columnHeader {
  display: block;
  font-weight: bold;
  text-align: center;
  padding-bottom: var(--uxu-space-default);

  @include media('m') {
    text-align: left;
  }
}
