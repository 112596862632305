@import "./../../../style/mixins";

.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: var(--uxu-space-small) 0;
  backdrop-filter: blur(1rem);
  background-color: rgba(var(--uxu-color-background-default-rgba), 0.5);
  border-bottom: var(--uxu-border-default);
  z-index: 999;
}

.headerContainer {
  height: calc(6.3rem - var(--uxu-space-small) * 2);
  display: flex;
  align-items: center;
}

.grid-container {
  display: flex;
  align-items: center;
  gap: var(--uxu-space-default);
}

.left, .right {
  @extend .grid-container;
}

.right {
  display: none;
  @include media(m) {
    display: flex;
  }
}

.left {
  width: 100%;
  justify-content: flex-start;

  @include media(m) {
    .hamburger {
      display: none;
    }
  }
}

.right {
  max-width: fit-content;
  padding-left: var(--uxu-space-default);
}

.modalMenu {
  width: 100%;
  height: calc(100% - 6.4rem);
  top: 6.4rem;
  left: 0;
  padding: var(--uxu-space-default);
}

.modalMenuContainer {
  display: flex;
  flex-direction: column;
  gap: var(--uxu-space-default);
  padding: var(--uxu-space-default) 0;
}
