@import "../../../style/mixins";

.note {
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  gap: var(--uxu-space-default);
  flex-direction: column;
  border-radius: var(--uxu-radii-default);
  padding: var(--uxu-space-small) var(--uxu-space-default);

  @include media('m') {
    display: grid;
    text-align: left;
    grid-template-columns: 2.4rem 1fr;
  }

}

.action {
  grid-template-columns: 2.4rem 1fr auto;
}

.default, .success, .warning, .error, .danger {
  background: transparent;
  color: var(--uxu-color-text-default);
}

.default, .defaultFill {
  border: var(--uxu-border-default);
}

.success, .successFill {
  color: var(--uxu-color-success-1);
  border: var(--uxu-border-success);
}

.warning, .warningFill {
  color: var(--uxu-color-warning-1);
  border: var(--uxu-border-warning);
}

.danger, .dangerFill {
  color: var(--uxu-color-danger-1);
  border: var(--uxu-border-danger);
}

.error, .errorFill {
  color: var(--uxu-color-error-1);
  border: var(--uxu-border-error);
}

.defaultFill {
  color: var(--uxu-color-text-default);
  background-color: var(--uxu-color-background-secound);
}

.successFill {
  background-color: rgba(var(--uxu-color-success-1-rgba), 0.1);
}

.warningFill {
  background-color: rgba(var(--uxu-color-warning-1-rgba), 0.1);
}

.dangerFill {
  background-color: rgba(var(--uxu-color-danger-1-rgba), 0.1);
}


.errorFill {
  background-color: rgba(var(--uxu-color-error-1-rgba), 0.1);
}
