.label {
  width: 100%;
  display: grid;
  position: relative;
  gap: var(--uxu-space-small);

  &::after {
    content: '^';
    line-height: 0.8;
    position: absolute;
    top: 1.8rem;
    right: var(--uxu-space-default);
    transform: translateY(-50%) rotate(180deg);
    width: 1rem;
    height: 1rem;
    display: block;
    color: white;
    z-index: 1;
  }
}

.select {
  width: 100%;
  height: 4rem;
  display: block;
  position: relative;
  padding: 0 calc(var(--uxu-space-default) *2) 0 var(--uxu-space-default);
  border: var(--uxu-border-default);
  border-radius: var(--uxu-radii-default);
  background: rgba(var(--uxu-color-background-default-rgba), 0.6);
  color: var(--uxu-color-text-default);
  transition: border-color var(--uxu-motion-default);
  appearance: none;

  &:focus {
    outline: none;

    &::placeholder {
      opacity: 0;
    }
  }

  option {
    height: 4rem;
    padding: var(--uxu-space-default);
  }
}

.message {
  font-size: var(--uxu-font-size-mini);
}
