.wrapper {
  display: flex;
  align-items: center;
  height: 2.5rem;
  line-height: 0.1;
  gap: var(--uxu-space-small);
  padding: 0 var(--uxu-space-small);
  font-size: var(--uxu-font-size-small);
  border: var(--uxu-border-default);
  border-radius: var(--uxu-radii-default);
  color: var(--uxu-color-text-default);
  background-color: var(--uxu-color-background-secound);
}
