@import '../../../../style/mixins';

.logo {
  height: 3.2rem;

  &.logoDesktop {
    display: none;
  }

  &.logoMobile {
    display: block;
  }

  svg {
    height: 3.2rem;
    path {
      fill: var(--uxu-color-link-secound);
    }
  }

  &:hover {
    svg {
      path {
        fill: var(--uxu-color-link-hover);
      }
    }
  }

  @include media(m) {
    padding-right: var(--uxu-space-default);
    &.logoDesktop {
      display: block;
    }
    &.logoMobile {
      display: none;
    }
    svg {
      height: 3.2rem;
    }
  }
}

.nav {
  display: none;

  @include media(m) {
    display: flex;
    list-style: none;
    gap: var(--uxu-space-default);

    a {
      color: var(--uxu-color-link-secound);
      font-size: var(--uxu-font-size-small);

      &:hover {
        color: var(--uxu-color-link-hover);
      }

      &.active {
        font-weight: bold;
        color: var(--uxu-color-link-hover);
      }
    }
  }
}

.wrapperSearch {
  width: 20rem;
  margin-left: auto;

  @include media(m) {
    width: 35rem;
  }
}
