.searchResultList {
  display: flex;
  flex-direction: column;
  list-style: none;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  padding: 0 var(--uxu-space-default);

  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 0;
    top: var(--uxu-searchResultList);
    left: 0;
    right: 0;
    height: 6rem;
    width: calc(100% - calc(var(--uxu-space-small) * 2));
    background-color: var(--uxu-color-background-default);
    transition: top var(--uxu-motion-fast) ease;
    border-radius: var(--uxu-radii-default);
    margin: 0 var(--uxu-space-small);
  }

  li {
    z-index: 2;
    display: flex;
    align-items: center;
  }

  a {
    width: 100%;
    height: 6rem;
    display: flex;
    align-items: center;
    gap: var(--uxu-space-default);
    background-color: transparent;
    color: var(--uxu-color-link-secound);
  }

  .content {
    display: block;
    max-width: calc(100% - 5rem);
    position: relative;

    & > * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .title,
    .lead {
      display: flex;
      align-items: center;
      max-width: 100%;
      position: relative;
    }

    .lead {
      min-height: 2.2rem;
      color: var(--uxu-color-text-default);
      padding-left: var(--uxu-space-default);
      font-size: var(--uxu-font-size-mini);

      &:before {
        content: "";
        top: 0.1rem;
        left: 0;
        display: block;
        width: 0.2rem;
        height: 1.5rem;
        position: absolute;
        background-color: var(--uxu-color-border-default);
      }
    }
  }
}
