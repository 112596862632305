@import "../../../../style/mixins";

.wrapper {
  display: flex;
  min-width: 1.2rem;
  min-height: 3.5rem;
  align-items: center;
  justify-content: flex-start;
  gap: var(--uxu-space-default);
  font-size: var(--uxu-font-size-small);
  border-radius: var(--uxu-radii-default);
  background-color: var(--uxu-color-background-secound);
  padding: var(--uxu-space-small) var(--uxu-space-small) var(--uxu-space-small) var(--uxu-space-default);

  @include media(s) {
    min-width: 20rem;
  }
}

.shortcut {
  margin-left: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0;

  @include media(m) {
    align-items: center;
    padding: var(--uxu-space-default);
  }
}

.searchResultList {
  display: flex;
  flex-direction: column;
  list-style: none;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  padding: 0 var(--uxu-space-default);

  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 0;
    left: 0;
    width: calc(100% - calc(var(--uxu-space-small) * 2));
    height: 6rem;
    background-color: var(--uxu-color-background-default);
    transition: top var(--uxu-motion-fast) ease;
    border-radius: var(--uxu-radii-default);
    margin: 0 var(--uxu-space-small);
  }

  li {
    z-index: 2;
    display: flex;
    align-items: center;
  }

  a {
    width: 100%;
    height: 6rem;
    display: flex;
    align-items: center;
    gap: var(--uxu-space-default);
    background-color: transparent;
  }

  .content {
    display: block;
    max-width: calc(100% - 5rem);
    position: relative;

    .lead {
      display: flex;
      align-items: center;
      max-width: 100%;
      min-height: 2.2rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      font-size: var(--uxu-font-size-mini);
      padding-left: var(--uxu-space-default);

      &:before {
        content: "";
        top: 0.1rem;
        left: 0;
        display: block;
        width: 0.4rem;
        height: 1.5rem;
        position: absolute;
        background-color: var(--uxu-color-primary-accent3);
      }
    }
  }
}

