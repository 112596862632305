.label {
  width: 100%;
  display: grid;
  position: relative;
  gap: var(--uxu-space-small);
}

.textarea {
  width: 100%;
  min-height: 10rem;
  display: block;
  border: var(--uxu-border-default);
  border-radius: var(--uxu-radii-default);
  background: rgba(var(--uxu-color-background-default-rgba), 0.6);
  color: var(--uxu-color-text-default);
  padding: var(--uxu-space-default);
  transition: border-color var(--uxu-motion-default);

  &::placeholder {
    color: var(--uxu-color-primary-accent3);
    opacity: 1;
    transition: opacity var(--uxu-motion-default);
  }

  &:focus {
    outline: none;
    border: var(--uxu-border-active);

    &::placeholder {
      opacity: 0;
    }
  }
}

.message {
  font-size: var(--uxu-font-size-mini);
}
