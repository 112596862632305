.wrapper {
  display: flex;
  position: relative;

  span {
    display: block;
    border-radius: 100%;
    margin: var(--uxu-space-small);
    background-color: var(--uxu-color-gray-6);
    animation-name: loading-dots_blink;
    animation-duration: 1.4s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }

  @keyframes loading-dots_blink {
    0% {
      opacity: 0.2
    }
    20% {
      opacity: 1
    }
    to {
      opacity: 0.2
    }
  }
}

