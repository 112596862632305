@import './../../../style/mixins';

.menuSpacer {
  top: 0;
  width: 100%;
  height: 64px;
  display: block;
  position: absolute;
  background: linear-gradient(90deg, rgba(var(--uxu-color-background-secound-rgba), 1) 0%, rgba(var(--uxu-color-background-secound-rgba), 1) 100%);
}

.wrapper{
  min-height: calc(100vh - 40rem);
}
