@import "./../theme/breakpoints";

@mixin media($breakpoint) {
  @media all and (min-width: map-get($breakpoints, $breakpoint) + px) {
    @content;
  }
}

@mixin dimensions($width, $height) {
  width: $width;
  height: $height;
}